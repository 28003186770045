import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface EventToCreate {
  roomId: string
  type: number
  startDate: Date
  endDate: Date
  occupants?: number
  note?: string
}

interface State {
  demandContactStatus: FetchStatus
  demandContactError?: string
  fetchCreateEventStatus: FetchStatus
  fetchCreateEventError?: string
}

export type FetchStatus = "idle" | "loading" | "success" | "error"

export const initialState: State = {
  demandContactStatus: "idle",
  fetchCreateEventStatus: "idle",
}

export const createReservation = createAsyncThunk(
  'reservation/createReservation',
  async ({ contactData, event }: { contactData: { name: string, email: string, website: string, phone?: string, message: string, file?: File[] }, event: EventToCreate }, { dispatch }) => {
    const formData = new FormData()
    formData.append('name', contactData.name)
    formData.append('email', contactData.email)
    formData.append('website', contactData.website)
    formData.append('phone', contactData.phone || "Non communiqué")
    formData.append('message', contactData.message)

    if (contactData.file && contactData.file.length > 0) {
      formData.append('file', contactData.file[0])
    }

    const contactResponse = await fetch(`${process.env.REACT_APP_BASE_CONTACT_URL}`, {
      method: 'POST',
      body: formData,
    })

    if (!contactResponse.ok) {
      const errorBody = await contactResponse.json()
      const errorMessage = errorBody.message[0]
      throw new Error(errorMessage)
    }

    const eventResponse = await fetch(`${process.env.REACT_APP_API_URL}/event/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(event)
    })

    if (!eventResponse.ok) {
      const reason = (await eventResponse.json()).message || "unknown_error"
      throw new Error(reason)
    }
  }
)

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    idleReservationStatus: (state) => {
      state.demandContactStatus = "idle"
      state.demandContactError = undefined
      state.fetchCreateEventStatus = "idle"
      state.fetchCreateEventError = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createReservation.pending, (state) => {
        state.demandContactStatus = "loading"
        state.fetchCreateEventStatus = "loading"
      })
      .addCase(createReservation.fulfilled, (state) => {
        state.demandContactStatus = "success"
        state.fetchCreateEventStatus = "success"
      })
      .addCase(createReservation.rejected, (state, action) => {
        state.demandContactStatus = "error"
        state.demandContactError = action.error.message
        state.fetchCreateEventStatus = "error"
        state.fetchCreateEventError = action.error.message
      })
  }
})

export const { idleReservationStatus } = reservationSlice.actions

export default reservationSlice.reducer
