import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import Skeleton from "react-loading-skeleton"
import { FaStar } from "react-icons/fa"
import { useTranslation } from 'react-i18next'
import { list } from '../data/Data'
import './rent.scss'
import { Carousel, Modal, Button } from 'antd'
import { getRoom, idleRooms, ApplicationRoom  } from "../home/room/redux/roomSlice"
import { AppDispatch, RootState } from '../../store'
import { useNavigate } from "react-router-dom"

const getRoomTypeName = (type: number | undefined, t: (key: string) => string) => {
  switch (type) {
    case 0:
      return t('Big Double Room')
    case 1:
      return t('Double Room')
    case 2:
      return t('Master Room')
    case 3:
      return t('Lodging')
    case 4:
      return t('Family Room')
    case 5:
      return t('Big Simple Room')
    default:
      return t('Simple Room')
  }
}

const Rent = () => {
  const { t, i18n } = useTranslation('rooms')
  const { id } = useParams<{ id: string }>()
  const dispatch: AppDispatch = useDispatch()
  const apiURL = process.env.REACT_APP_API_URL
  const roomFromRedux = useSelector((state: RootState) => state.room.room)
  const getRoomStatus = useSelector((state: RootState) => state.room.getRoomStatus)
  const [imageSrc, setImageSrc] = useState<string[]>([])
  const [name, setName] = useState<string>()
  const [type, setType] = useState<string>()
  const [availability, setAvailability] = useState<string>()
  const [capacity, setCapacity] = useState<number>()
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const navigate = useNavigate()
  const path = 'https://drive.google.com/file/d/1p7dBNLUvyCOzfJJGTNP1bRgB2kp6jOv1/view?usp=sharing'

  useEffect(() => {
    if (apiURL !== "https://aucoeur-back-main.onrender.com" && id) {
      dispatch(getRoom({ roomId: id }))
    }
    return () => {
      dispatch(idleRooms())
    }
  }, [dispatch, apiURL, id])

  useEffect(() => {
    const room = list.find(val => val._id === id)
    const item = apiURL === "https://aucoeur-back-main.onrender.com" ? room : roomFromRedux
    if (item && id) {
      setType(getRoomTypeName(item.type, t))
      setName(item.name)
      setAvailability(item.active ? t('Available') as string : t('Unavailable') as string)
      setCapacity(item.capacity)
      setImageSrc(item.cover ? (item.additionalImages ? [item.cover, ...item.additionalImages] : [item.cover]) : ['../images/about.jpg'])
    } else {
      setImageSrc(['../images/about.jpg'])
      setName('Unknown')
      setAvailability('Unknown')
      setCapacity(0)
    }
  }, [id, t, i18n.language, roomFromRedux, apiURL])  

  const handlePreview = (src: string) => {
    setPreviewImage(src)
    setPreviewVisible(true)
  }

  const handleNavigation = () => {
    if(path.slice(0, 5) === "https"){
      window.open(path, '_blank')
    }
    else{
      navigate(path)
      window.scrollTo(0, 0)
    }
  }

  const Loading = () => {
    return (
      <>
        <div className="col-md-6 skeleton">
          <Skeleton height={400} />
        </div>
        <div className="col-md-6 skeleton" style={{ lineHeight: 2 }}>
          <Skeleton height={50} width={300} />
          <Skeleton height={75} />
          <Skeleton height={25} width={150} />
          <Skeleton height={50} />
          <Skeleton height={150} />
          <Skeleton height={50} width={100} />
          <Skeleton height={50} width={100} style={{ marginLeft: 6 }} />
        </div>
      </>
    )
  }

  const ShowProduct = () => {
    if (!name) return null
    return (
      <>
        <div className="col-md-6">
          <Carousel 
            autoplay 
            draggable 
            arrows 
          >
            {imageSrc.map((src, index) => (
              <div key={index} onClick={() => handlePreview(src)}>
                <img src={src} alt={name} className="carousel-image" />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="col-md-6">
          <h4 className="text-uppercase text-black-50">{availability}</h4>
          <h1 className="display-5">{name}</h1>
          <p className="lead fw-bolder">
            <FaStar />
              {type}
            <FaStar />
          </p>
          <p className="lead">{t('For_people', { count: capacity })}</p>
          <button onClick={handleNavigation} className="show-more-button">
            {t('For more information')}
          </button>
        </div>
        <Modal
          className="custom-modal"
          open={previewVisible}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt="preview" src={previewImage} />
        </Modal>
      </>
    )
  }
  

  return (
    <div>
      <div className="container py-5">
        <div className="row py-4">
          {getRoomStatus === 'loading' ? <Loading /> : <ShowProduct />}
        </div>
      </div>
    </div>
  )
}

export default Rent
