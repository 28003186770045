import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface State {
  demandContactStatus: FetchStatus
  demandContactError?: string
}

export type FetchStatus = "idle" | "loading" | "success" | "error"

export const initialState: State = {
  demandContactStatus: "idle",
}

export const sendDemandContact = createAsyncThunk(
  'demandContact/demandContactStatus',
  async (contactData: {
    name: string
    email: string
    phone?: string
    website: string
    message: string
    file: File[]
  }) => {
    const formData = new FormData()
    formData.append('name', contactData.name)
    formData.append('email', contactData.email)
    formData.append('phone', contactData.phone || "Non communiqué")
    formData.append('website', contactData.website)
    formData.append('message', contactData.message)

    if (contactData.file && contactData.file.length > 0) {
      formData.append('file', contactData.file[0])
    }

    const response = await fetch(`${process.env.REACT_APP_BASE_CONTACT_URL}`, {
      method: 'POST',
      body: formData,
    })

    if (!response.ok) {
      const errorBody = await response.json()
      const errorMessage = errorBody.message[0]
      throw new Error(errorMessage)
    }  
  }
)

const demandContactSlice = createSlice({
  name: 'demandContact',
  initialState,
  reducers: {
    idledemandContactStatus: (state) => {
      state.demandContactStatus = "idle"
      state.demandContactError = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendDemandContact.pending, (state) => {
        state.demandContactStatus = "loading"
      })
      .addCase(sendDemandContact.fulfilled, (state) => {
        state.demandContactStatus = "success"
      })
      .addCase(sendDemandContact.rejected, (state, action) => {
        state.demandContactStatus = "error"
        state.demandContactError = action.error.message
      })
  },
})

export const { idledemandContactStatus } = demandContactSlice.actions

export default demandContactSlice.reducer